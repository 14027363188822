import EventSlideshow from "./EventsSlideshow";

const LandingSlideshow = () => {
  return (
    <div className="flex">
      <EventSlideshow />
    </div>
  );
};
export default LandingSlideshow;
