import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import HomeRoute from './routes/HomeRoute';
import PaymentPage from './pages/PaymentPage';
import SponsPage from './pages/SponsPage'; 

const App = () => {
  return (
    <Router>
      <Routes> {/* Use Routes instead of Switch */}
        <Route path="/" element={<HomeRoute />} /> {/* Use element prop instead of component */}
        <Route path="/payment" element={<PaymentPage />} /> {/* Use element prop instead of component */}
        
        <Route path="/Sponsors" element={<SponsPage />} />
      </Routes>
    </Router>
  );
};

export default App;
