import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDjvZTE-O84xd1kPNwP-fk4jkCSbkJ4jag",
  authDomain: "tedxiitgandhinagar-3d917.firebaseapp.com",
  projectId: "tedxiitgandhinagar-3d917",
  storageBucket: "tedxiitgandhinagar-3d917.appspot.com",
  messagingSenderId: "756144145351",
  appId: "1:756144145351:web:77322d6c723adb9a1a8fe2",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup };
