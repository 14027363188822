import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sponsors from "../components/SponsComp";

const SponsPage = () => {
  return (
    <>
      <Navbar />
      <Sponsors />
      <Footer />
    </>
  );
};

export default SponsPage;
