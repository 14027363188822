import { auth, provider, signInWithPopup } from "../utils/firebaseConfig";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PaymentPopup from "./PaymentPopup";
import img from "../assets/images/qr.jpg";
import Button from "./Button";

const workshops = [
  { name: "VR (Sold out)", iitgnPrice: 100, nonIitgnPrice: 200 },
  { name: "ISRO (Sold out)", iitgnPrice: 100, nonIitgnPrice: 200 },
  { name: "Cooking (Sold out)", iitgnPrice: 100, nonIitgnPrice: 200 },
  { name: "Art", iitgnPrice: 100, nonIitgnPrice: 150 },
];

const PaymentForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [iitgnData, setIitgnData] = useState([]);
  const [nonIitgnData, setNonIitgnData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [isFormComplete, setIsFormComplete] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showWorkshops, setShowWorkshops] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const handleGoogleLogin = e => {
    e.preventDefault();
    signInWithPopup(auth, provider)
      .then(result => {
        const email = result.user.email;
        console.log(email);
        if (email) {
          setFormData({
            ...formData,
            email,
          });
        } else {
        }
      })
      .catch(error => {
        console.error("Error during sign-in", error);
        alert("Please open this site in chrome and login again");
      });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSuccess = () => {
    navigate("/", { state: { showToast: true } });
  };

  const handleWorkshopChange = (e, index, group) => {
    const { name, checked } = e.target;

    const updateData = data =>
      data.map((participant, i) => {
        if (i === index) {
          return {
            ...participant,
            workshops: {
              ...participant.workshops,
              [name]: checked,
            },
          };
        }
        return participant;
      });

    if (group === "iitgn") {
      setIitgnData(updateData(iitgnData));
    } else {
      setNonIitgnData(updateData(nonIitgnData));
    }
  };

  useEffect(() => {
    const checkEmailDomain = email => {
      if (email.endsWith("@iitgn.ac.in")) {
        setNonIitgnData([]); // Clear non-IITGN data if IITGN email detected
        if (iitgnData.length === 0) {
          setIitgnData([{ name: formData.name, email: formData.email, workshops: {} }]);
        }
      } else {
        if (nonIitgnData.length === 0) {
          setNonIitgnData([{ name: formData.name, email: formData.email, workshops: {} }]);
        }
      }
    };

    if (!formData.email) {
      // Ensure non-IITGN workshop list is shown initially
      if (nonIitgnData.length === 0) {
        setNonIitgnData([{ name: formData.name, workshops: {} }]);
      }
    } else {
      checkEmailDomain(formData.email);
    }
  }, [formData.email, formData.name, iitgnData.length, nonIitgnData.length]);

  const calculateTotal = (data, workshopPrices) =>
    data.reduce(
      (sum, participant) =>
        sum +
        Object.entries(participant.workshops).reduce((acc, [workshop, checked]) => {
          const price = workshopPrices[workshop] || 0;
          return acc + (checked ? price : 0);
        }, 0),
      0
    );

  const applyDiscount = (total, uniqueTicketCount) => {
    console.log(`Applying discount: Total: ${total}, Unique Ticket Count: ${uniqueTicketCount}`);
    // if (uniqueTicketCount === 2) {
    //   return total * 0.85; // 15% discount
    // } else if (uniqueTicketCount >= 3) {
    //   return total * 0.75; // 25% discount
    // }
    return total; // No discount
  };

  useEffect(() => {
    const iitgnPrices = Object.fromEntries(workshops.map(workshop => [workshop.name, workshop.iitgnPrice]));
    const nonIitgnPrices = Object.fromEntries(workshops.map(workshop => [workshop.name, workshop.nonIitgnPrice]));

    const totalIitgn = calculateTotal(iitgnData, iitgnPrices);
    const totalNonIitgn = calculateTotal(nonIitgnData, nonIitgnPrices);

    const total = totalIitgn + totalNonIitgn;
    setTotalAmount(total);

    // Count unique selected workshops
    const uniqueWorkshops = new Set();
    iitgnData.forEach(participant => {
      Object.entries(participant.workshops).forEach(([workshop, selected]) => {
        if (selected) uniqueWorkshops.add(workshop);
      });
    });
    nonIitgnData.forEach(participant => {
      Object.entries(participant.workshops).forEach(([workshop, selected]) => {
        if (selected) uniqueWorkshops.add(workshop);
      });
    });

    const uniqueTicketCount = uniqueWorkshops.size;
    const discountedTotal = applyDiscount(total, uniqueTicketCount);
    setDiscountedAmount(discountedTotal);

    console.log(`Total Amount: ${total}, Discounted Amount: ${discountedTotal}`);

    const allIitgnFilled = iitgnData.every(participant => participant.name && participant.email);
    const allNonIitgnFilled = nonIitgnData.every(participant => participant.name && participant.email);

    const isAnyWorkshopSelected = uniqueWorkshops.size > 0;

    setIsFormComplete(formData.name && formData.email && (allIitgnFilled || allNonIitgnFilled) && isAnyWorkshopSelected);
    // eslint-disable-next-line
  }, [iitgnData, nonIitgnData, formData, workshops]);

  const handleCheckout = () => {
    if (isFormComplete) {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    navigate("/");
  };

  const collectFormData = () => {
    return {
      organizer: {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
      },
      participants: {
        iitgn: iitgnData,
        nonIitgn: nonIitgnData,
      },
      totalAmount: discountedAmount.toFixed(2), // Moved this outside
    };
  };

  return (
    <div className="flex bg-black text-white p-8 pt-40 rounded-lg shadow-lg max-w-4xl mx-auto py-32">
      <div className="form-section w-full pr-4">
        <h2 className="text-4xl mb-12 text-center">Participant Details</h2>

        <form>
          <div className="mb-4">
            {formData.email.length > 0 && <label className="block mb-2 text-neutral-300">Email: {formData.email}</label>}
            {/* <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
              disabled
            /> */}
            {!formData.email && (
              <Button onClick={handleGoogleLogin} className="rounded-xl">
                Login with Google
              </Button>
            )}
            <br />
            <div className="text-neutral-400 py-4">
              For special discounts please use your IITGN email.
              <br />
              <br />
              (Please Note that the tickets will be sent to the email address associated with your Google Account. If you are facing any issues in logging in,
              please switch to Chrome browser)
            </div>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            />
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-neutral-300">Phone Number</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="bg-transparent focus:outline-0 px-4 w-full py-2.5 rounded-md"
              style={{ backgroundColor: "#171717" }}
            />
          </div>

          {/* {formData.name && formData.email && formData.phone && (
            <Button type="button" onClick={handleViewWorkshops} className="rounded-xl">
              View Workshops
            </Button>
          )} */}
        </form>

        {showWorkshops && (
          <>
            {/* IITGN Participants */}
            {iitgnData.map((participant, index) => (
              <div key={index} className="mb-4 rounded-md mt-2">
                <h3 className="text-lg text-neutral-300 py-4">Choose Your Workshops</h3>

                {workshops.map(workshop => (
                  <div
                    key={workshop.name}
                    className="flex justify-between items-center py-3 px-3 rounded-lg bg-black text-white hover:bg-neutral-800 transition duration-300"
                  >
                    <label className="flex-1 cursor-pointer">
                      <input
                        type="checkbox"
                        name={workshop.name}
                        checked={participant.workshops[workshop.name] || false}
                        onChange={e => handleWorkshopChange(e, index, "iitgn")}
                        className="mr-2 cursor-pointer"
                        disabled={workshop.name.includes("Sold out")}
                      />
                      <span className={workshop.name.includes("Sold out") ? "line-through" : ""}>{workshop.name}</span>
                    </label>
                    <span className="font-bold text-neutral-500">₹{workshop.iitgnPrice}</span>
                  </div>
                ))}
              </div>
            ))}

            {/* Non-IITGN Participants */}
            {nonIitgnData.map((participant, index) => (
              <div key={index} className="mb-4 rounded-md mt-2">
                <h3 className="text-lg text-neutral-300 py-4">Choose Your Workshops</h3>

                {workshops.map(workshop => (
                  <div
                    key={workshop.name}
                    className="flex justify-between items-center py-3 px-3 rounded-lg bg-black text-white hover:bg-neutral-800 transition duration-300"
                  >
                    <label className="flex-1 cursor-pointer">
                      <input
                        type="checkbox"
                        name={workshop.name}
                        checked={participant.workshops[workshop.name] || false}
                        onChange={e => handleWorkshopChange(e, index, "nonIitgn")}
                        className="mr-2 cursor-pointer"
                        disabled={workshop.name.includes("Sold out")}
                      />
                      <span className={workshop.name.includes("Sold out") ? "line-through" : ""}>{workshop.name}</span>
                    </label>
                    <span className="font-bold text-neutral-500">₹{workshop.nonIitgnPrice}</span>
                  </div>
                ))}
              </div>
            ))}

            <div className="text-lg  mt-4">
              {/* <span className="">Actual Price: ₹{totalAmount} </span> <br />
              <span className="">Discount: ₹{totalAmount - discountedAmount.toFixed(2)} </span> <br /> */}
              <span className="text-red-400">Final Amount: ₹{discountedAmount.toFixed(2)}</span>
            </div>

            <Button
              onClick={handleCheckout}
              className={`mt-4 p-2 rounded-xl  ${!isFormComplete ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={!isFormComplete}
            >
              Checkout
            </Button>

            {showPopup && (
              <PaymentPopup onClose={handleClosePopup} img={img} totalAmount={discountedAmount} formData={collectFormData()} onSuccess={handleSuccess} />
            )}
          </>
        )}
      </div>

      <div className="hidden lg:flex justify-center items-center flex-1">
        <img src={img} alt="QR Code" className="h-64" />
      </div>
    </div>
  );
};

export default PaymentForm;
