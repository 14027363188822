import React, { useState } from "react";

const PaymentPopup = ({ onClose, img, formData, totalAmount, apiUrl }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null); // For error handling
  const [loading, setLoading] = useState(false); // To manage loading state

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };

  const handleFileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Get the Base64 string
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(file); // Read the file as a Data URL
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!file) {
      setError("No file selected!");
      return;
    }

    setLoading(true); // Start loading
    const base64File = await handleFileToBase64(file); // Convert to Base64

    const formDataToSend = new FormData();
    formDataToSend.append("file", base64File); // Append the Base64 string
    formDataToSend.append("formData", JSON.stringify(formData));

    console.log("Sending data:", formData);
    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbyoowmHon-i0XaRhRYeqlxmCfYsykSiGUtBt8REeYAOEciyLsBtrrN5BUJE7rrrqWHW/exec", {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Submission successful:", result);

      // Simulate a successful submission for testing
      console.log("Simulated: File and data uploaded successfully!");
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("Failed to submit the form. Please try again.");
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-black w-96 max-w-full">
        <h2 className="text-2xl font-bold mb-4 text-center">Checkout</h2>
        <img src={img} alt="QR Code" className="mb-4 mx-auto" />
        <div>Total Amount = Rs. {totalAmount}</div>
        {error && <div className="text-red-600 mb-4">{error}</div>} {/* Display error message */}
        <form onSubmit={handleSubmit}>
          <label className="block mb-2">
            Upload Payment Screenshot:
            <input type="file" accept="image/*" onChange={handleFileChange} required className="block w-full p-2 border border-gray-300 rounded-md" />
          </label>
          <button
            type="submit"
            className={`bg-red-600 text-white py-2 px-4 rounded-md mt-4 w-full ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
        <button onClick={onClose} className="mt-4 text-gray-500 underline w-full text-center">
          Close
        </button>
      </div>
    </div>
  );
};

export default PaymentPopup;
