const Slide = ({ backgroundURL, children }) => {
  return (
    <div className="w-full h-full flex-shrink-0 snap-center md:px-14 z-10">
      <div className="relative w-full h-full">
        <img src={backgroundURL} alt="" className="w-full h-full object-cover md:rounded-xl" />
        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center items-center text-white p-8 z-10">{children}</div>
      </div>
    </div>
  );
};

export default Slide;
