import React, { useEffect, useState, useRef } from "react";

const AnimatedText = ({ text, className, style }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [lines, setLines] = useState([]);
  const paragraphRef = useRef(null);
  const hiddenRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => setIsVisible(true), 200);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (paragraphRef.current) {
      observer.observe(paragraphRef.current);
    }

    return () => {
      if (paragraphRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(paragraphRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const calculateLines = () => {
      if (hiddenRef.current) {
        const hiddenElement = hiddenRef.current;
        const lineHeight = parseInt(window.getComputedStyle(hiddenElement).lineHeight);
        const words = text.split(" ");
        let currentLine = "";
        const newLines = [];

        words.forEach((word, index) => {
          const testLine = currentLine + (currentLine ? " " : "") + word;
          hiddenElement.textContent = testLine;

          if (hiddenElement.offsetHeight > lineHeight) {
            newLines.push(currentLine);
            currentLine = word;
          } else {
            currentLine = testLine;
          }

          if (index === words.length - 1) {
            newLines.push(currentLine);
          }
        });

        setLines(newLines);
      }
    };

    setTimeout(calculateLines, 1000);

    calculateLines();
    window.addEventListener("resize", calculateLines);

    return () => {
      window.removeEventListener("resize", calculateLines);
    };
  }, [text]);

  return (
    <div ref={paragraphRef} className="relative overflow-hidden">
      <div
        ref={hiddenRef}
        aria-hidden="true"
        className={"absolute top-0 left-0 -z-10 invisible whitespace-pre-wrap break-words " + className}
        style={{ width: "100%", ...style }}
      >
        {text}
      </div>
      {lines.map((line, index) => (
        <div
          key={index}
          className={`transition-all duration-500 ease-out ${isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-full"} ${className}`}
          style={{ transitionDelay: `${index * 50}ms`, ...style }}
        >
          {line}
        </div>
      ))}
    </div>
  );
};

export default AnimatedText;
