import PropTypes from "prop-types";

const Button = ({ children, className, onClick }) => {
  return (
    <button onClick={onClick} className={"bg-white w-fit text-black py-2 px-6 my-2 rounded-full hover:bg-gray-200 transition " + className}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
