import { motion } from "framer-motion";

const Quote = ({ text, subtext }) => {
  return (
    <div>
      <div className="flex-col gap-y-5 flex content-center items-center text-center max-w-[68rem] mx-auto py-20 px-10">
        <motion.div
          className="lg:text-3xl text-2xl text-copy-primary text-pretty"
          style={{ animationDelay: "200ms" }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
        >
          “{text}”
        </motion.div>
        <motion.div className="" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, delay: 1 }}>
          {subtext}
        </motion.div>
      </div>
    </div>
  );
};

export default Quote;
