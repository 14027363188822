import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import Link from react-router-dom
import Navbar from "../components/Navbar";
import LandingSlideshow from "../components/LandingSlideshow";
import { toast, ToastContainer } from "react-toastify";
import About from "../components/About";
import Footer from "../components/Footer";
import Quote from "../components/Quote";
import EventDetails from "../components/EventDetails";
import Speakers from "../components/Speakers";
import Sponsors from "../components/Sponsors";

const HomeRoute = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("Location state:", location.state);
    if (location.state && location.state.showToast) {
      toast.success("Registration Successful! Tickets will be sent through email.");
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <LandingSlideshow />
      <Quote
        text="The world as we have created it is a process of our thinking. It cannot be changed without changing our thinking. When we embrace new ideas, challenge our assumptions, and step into the unknown, we find the power to shape not just our own future, but the future of the world."
        subtext="Inspired by Albert Einstein"
      />
      <ToastContainer />
      <div className="payment-button-container"></div>
      <About />
      <Speakers />
      <Sponsors />
      <EventDetails
        eventName="Attend TEDxWorkshops 2024"
        eventDesc="Attending a live TEDx event is interactive, engaging and exciting experience with other TED-loving people in our amazing college IIT Gandhinagar."
        eventVenue={["@ IIT Gandhinagar", "Gandhinagar, Gujarat"]}
        eventTime={["27th October, ", "9:00 AM — 7:00 PM"]}
      />
      <Footer />
    </>
  );
};

export default HomeRoute;
