const HamburgerIcon = ({ onClick, open }) => {
  return (
    <div
      className="flex flex-col gap-[0.1875rem] cursor-pointer z-50"
      onClick={() => {
        onClick();
      }}
    >
      <div
        className={
          open
            ? "w-[0.9375rem] h-[0.125rem] bg-white rounded-full transition ease-curve-d duration-300 translate-y-[0.3125rem] rotate-45 w-[1.125rem]"
            : "w-[0.9375rem] h-[0.125rem] bg-white rounded-full transition ease-curve-d duration-300"
        }
      ></div>
      <div
        className={
          open
            ? "w-[0.9375rem] h-[0.125rem] bg-white rounded-full transition ease-curve-d duration-300 opacity-0"
            : "w-[0.9375rem] h-[0.125rem] bg-white rounded-full transition ease-curve-d duration-300"
        }
      ></div>
      <div
        className={
          open
            ? "w-[0.9375rem] h-[0.125rem] bg-white rounded-full transition ease-curve-d duration-300 -translate-y-[0.3125rem] -rotate-45 w-[1.125rem]"
            : "w-[0.9375rem] h-[0.125rem] bg-white rounded-full transition ease-curve-d duration-300"
        }
      ></div>
    </div>
  );
};

export default HamburgerIcon;
