import Speaker from "./Speaker";
import shailly from "../assets/images/Shailly Gajjar.webp";
import caperment from "../assets/images/capermint.webp";

const Speakers = () => {
  return (
    <div className="max-w-[68rem] mx-auto flex flex-col justify-center py-6" id="workshops">
      <div className="lg:text-5xl text-4xl text-center py-10">Workshops</div>

      <Speaker
        name="The Chef's Palette"
        photo="https://yt3.googleusercontent.com/ytc/AIdro_lUbQowFqJf5u4AUhjSHey-AuZV5TdcqiyHnjCHAU84Qg=s900-c-k-c0x00ffffff-no-rj"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
          quidem"
        tagline={
          <div>
            <div className="mb-4">Kanak Khathuria, Masterchef Season 1 Finalist</div>
            <div>Venue: Ground Floor, Rgouras Mess</div>
            <div>Time: 27th October, 9:00 AM — 12:00 PM</div>
          </div>
        }
        profileLink="https://drive.google.com/file/d/1_DkH7iwyDAPAvvVnh7oUp2d98Ok6tzp3/view?usp=sharing"
      />
      <Speaker
        name="Inkspire: Bringing your ideas to life"
        photo={shailly}
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">Shailly Gajjar, Illustrative Graphic Designer </div>
            <div>Venue: Academic Building 10/201, First Floor</div>
            <div>Time: 27th October, 9:00 AM — 11:00 AM</div>
          </div>
        }
        profileLink="https://drive.google.com/file/d/1AEdWsIlDN7OAqkroPZQ62Rx0faiTckA0/view?usp=sharing"
      />
      <Speaker
        name="Sculpting New Realities: VR Workshop"
        photo={caperment}
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
        quidem"
        tagline={
          <div>
            <div className="mb-4">Step Beyond the Screen and Into a New Dimension</div>
            <div>Venue: Academic Building 10/103, Ground Floor</div>
            <div>Time: 27th October, 11:00 AM — 1:30 PM</div>
          </div>
        }
        profileLink="https://drive.google.com/file/d/1E6dmECjVHUTcD8sLmWvDmm2o9VEKN39w/view?usp=sharing"
      />
      <Speaker
        name="Exploring Space Engineering"
        photo="https://i.gadgets360cdn.com/large/isro_reuters_1724398653537.jpg"
        desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium saepe quasi cumque! Voluptates iusto exercitationem minima voluptatibus hic
      quidem"
        tagline={
          <div>
            <div className="mb-4">From Concept to Creation: Understanding the Dynamics of Space Technology</div>
            <div>Venue: Jibaben Patel Memorial Auditorium</div>
            <div>Time: 27th October, 3:00 PM — 6:00 PM</div>
          </div>
        }
        profileLink="https://drive.google.com/file/d/1lO684mO5AT13oJ4NaS0nzNE0MV0YT9yt/view?usp=sharing"
      />
    </div>
  );
};

export default Speakers;
