import Colours from "../utils/colours";
import AnimatedText from "./AnimatedText";

const About = () => {
  return (
    <div className="max-w-[68rem] mx-auto mb-32 mt-8 flex xl:flex-row-reverse flex-col items-center gap-10" id="about">
      <div className="xl:w-2/5 w-full px-10 flex items-center justify-center">
        <img
          className="w-full h-full object-contain"
          src="https://images.squarespace-cdn.com/content/v1/5afff457b98a78dfe64c781a/e685ab1f-9ab5-437f-9c13-d65afad639c6/2.jpg"
          alt=""
        />
        {/* <img className="w-full h-full bg-cover" src="https://sites.iitgn.ac.in/tedxiitgandhinagar/realleusable.svg" alt="" /> */}
      </div>
      <div className="xl:w-3/5 w-full px-10">
        <div className="py-8">
          <AnimatedText
            text="But wait"
            className="font-semibold uppercase tracking-wide google-sans text-sm lg:text-base"
            style={{ color: Colours.red }}
          ></AnimatedText>
          <AnimatedText text="What is TEDx?" className="lg:text-5xl text-3xl font-thin google-sans"></AnimatedText>
        </div>
        <AnimatedText
          className="leading-9 tracking-wide google-sans"
          text="TEDx is an initiative to promote 'ideas worth spreading' in local communities across the world. Steered by passionate individuals, TEDx events aim to
          infuse the spirit of TED at the grassroot level through a series of independently organised events. These events are aimed at bringing new ideas and
          stories to the society so as to inspire and spark meaningful conversations. These events, under the umbrella of TED, adhere to set of prescribed
          guidelines provided alongwith the license. More than 3000 events are held as part of TEDx each year."
        />
      </div>
    </div>
  );
};

export default About;
