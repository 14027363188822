import TEDxWorkshopsSlide from "./slides/TEDxWorkshopsSlide";
import React, { useState, useRef, useEffect, useCallback } from "react";
import TEDxWorkshopsSlide1 from "./slides/TEDxWorkshopsSlide1";
import TEDxWorkshopsSlide2 from "./slides/TEDxWorkshopsSlide2";
import TEDxWorkshopsSlide3 from "./slides/TEDxWorkshopsSlide3";
import TEDxWorkshopsSlide4 from "./slides/TEDxWorkshopsSlide4";

const EventsSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideshowRef = useRef(null);
  const timerRef = useRef(null);

  const numEvents = 5;

  const goToSlide = useCallback(index => {
    if (slideshowRef.current) {
      slideshowRef.current.scrollTo({
        left: index * slideshowRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  }, []);

  const nextSlide = useCallback(() => {
    const nextIndex = (currentSlide + 1) % numEvents;
    goToSlide(nextIndex);
  }, [currentSlide, numEvents, goToSlide]);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(nextSlide, 5000);
  }, [nextSlide]);

  useEffect(() => {
    resetTimer();
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [currentSlide, resetTimer]);

  useEffect(() => {
    const handleScroll = () => {
      if (slideshowRef.current) {
        const index = Math.round(slideshowRef.current.scrollLeft / slideshowRef.current.offsetWidth);
        if (index !== currentSlide) {
          setCurrentSlide(index);
          resetTimer();
        }
      }
    };

    const slideshow = slideshowRef.current;
    slideshow?.addEventListener("scroll", handleScroll);
    return () => slideshow?.removeEventListener("scroll", handleScroll);
  }, [currentSlide, resetTimer]);

  return (
    <div>
      <div className="relative w-full h-[80dvh] pt-20 overflow-hidden bg-black">
        <div ref={slideshowRef} className="flex h-full overflow-x-auto snap-x snap-mandatory no-scrollbar">
          <TEDxWorkshopsSlide />
          <TEDxWorkshopsSlide3 />
          <TEDxWorkshopsSlide2 />
          <TEDxWorkshopsSlide1 />
          <TEDxWorkshopsSlide4 />
        </div>
      </div>
      <div className="flex space-x-2 w-full mt-5 items-center justify-center">
        {Array(numEvents)
          .fill(undefined)
          .map((_, index) => (
            <div key={index} onClick={() => setCurrentSlide(index)} className={`w-2 h-2 rounded-full ${currentSlide === index ? "bg-white" : "bg-gray-400"}`} />
          ))}
      </div>
    </div>
  );
};

export default EventsSlideshow;
