import Colours from "../utils/colours";

const Speaker = ({ photo, name, tagline, desc, profileLink }) => {
  return (
    <div className="w-full flex lg:py-20 py-8 xl:even:flex-row-reverse xl:odd:flex-row flex-col items-center gap-10">
      <div className="xl:w-1/2 w-full px-10">
        <img className="grayscale rounded-xl opacity-50 w-full" src={photo} alt="" />
      </div>
      <div className="xl:w-1/2 w-full px-10 flex flex-col ">
        <div className="w-fit xl:px-10">
          <div className="lg:text-4xl text-3xl">{name}</div>
          <div className="text-neutral-500 py-4">{tagline}</div>
          {/* <div>{desc}</div> */}
          <a href={profileLink}>
            <span className="!bg-black flex items-center gap-4 nav-link w-fit font-bold" style={{ color: Colours.red }}>
              Know more
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Speaker;
