import React from "react";
import "./SponsComp.css";

import platspon from "../assets/images/platinum_sponsor.webp";
import goldspon from "../assets/images/gold_sponsor.webp";
import titlespon from "../assets/images/title_sponsor.webp";
import giftpartner from "../assets/images/gifting_partner.webp";

function Sponsors() {
  return (
    <div className="sponsors-page">
      <div className="lg:text-5xl text-4xl text-center py-20 mb-10 mt-20">Our Sponsors</div>
      <div className="sponsor-section">
        <h2>Title Sponsor</h2>
        <div className="sponsor-logo">
          <img src={titlespon} alt="Title Sponsor Logo" />
          <p>INDEXT-a</p>
        </div>
      </div>

      <div className="sponsor-section ">
        <h2>Platinum Sponsor</h2>
        <div className="sponsor-logo">
          <img src={platspon} alt="platinum spon Logo" />
          <p>Gujarat Tourism</p>
        </div>
      </div>

      <div className="sponsor-section ">
        <h2>Gold Sponsor</h2>
        <div className="sponsor-logo">
          <img src={goldspon} alt="Golden Sponsor Logo" />
          <p>Department Of Science And Technology</p>
          <p>Gujarat Council On Science And Technology</p>
        </div>
      </div>

      <div className="sponsor-section">
        <h2>Gifting Partner</h2>
        <div className="sponsor-logo">
          <img src={giftpartner} alt="Gifting Partner Logo" />
          <p>Radhe</p>
        </div>
      </div>
    </div>
  );
}

export default Sponsors;
